import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useSlot from '../../hooks/useSlots';
import { ClientFormUserRole, ClientFormUserRoleKeys, ClientFormUserRoleValues } from '../../models/ClientFormUserRoles';
import withSlot, { SlotDefinitions } from '../../wrappers/withSlot';
import Accordion, { useAccordionContext } from '../shared/accordion/Accordion';
import { useTranslation } from 'react-i18next';
import WarningInfoIcon from '../shared/icon/WarningInfoIcon';
import Tooltip from '../shared/Tooltip';
import { ClientFormUser } from '../../models/ClientFormUser';
import UserListRenderer from './UserListRenderer';
import { PeopleType } from '../../models/Distribution';
import { ImageSize, ProfileImageStack } from './ProfileImageStack';
import XIcon from '../shared/icon/XIcon';
import Checkbox, { SliderSize } from '../shared/form-control/Checkbox';
import { closestCenter, DndContext, DragEndEvent, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import DragHandleIcon from '../shared/icon/DragHandleIcon';
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import UsersSelect from './UsersSelect';
import useFetchClientUsers from '../../hooks/useFetchClientUsers';

type Props = {
  role: ClientFormUserRoleValues;
  users: ClientFormUser[];
  disabled?: boolean;
  warning?: string | null;
  enableReordering?: boolean;
  requiredToggle?: boolean;
  enableOrdering?: boolean;
  searchPhrase?: string;
  formSectionId?: string;
  formUsers: ClientFormUser[];
  ownerRequiresAction?: boolean;
  onUserRemove: (user: ClientFormUser) => void;
  onUserChange: (user: ClientFormUser) => void;
  onUpdateUsers: (users: ClientFormUser[]) => void;
  onUsersReorder?: (users: ClientFormUser[]) => void;
};

const ClientFormRoleGroup = withSlot<Props, SlotDefinitions<['empty', 'footer']>>((props) => {
  const {
    role,
    users,
    disabled,
    warning,
    onUserRemove,
    requiredToggle,
    onUserChange,
    enableOrdering,
    onUsersReorder,
    onUpdateUsers,
    searchPhrase = '',
    formSectionId,
    formUsers,
    ownerRequiresAction,
  } = props;
  const { t } = useTranslation(['common']);
  const [open, setOpen] = useState(true);
  const { data: clientUsers = [] } = useFetchClientUsers();

  const onUserRequiredChanged = useCallback(
    (user: ClientFormUser, required: boolean) => {
      onUserChange({ ...user, requiresAction: required });
    },
    [onUserChange],
  );

  const emptySlot = useSlot(
    props,
    'empty',
    useMemo(() => <span>{t('permissions-modal.group.placeholders.unassigned')}</span>, [t]),
  );

  const footerSlot = useSlot(props, 'footer');

  const accordionTitle = useMemo(
    () => (
      <div className="flex max-h-10 w-full items-center justify-between overflow-hidden">
        <div className="flex items-center gap-2">
          {warning && (
            <Tooltip text={warning}>
              {(tooltip) => (
                <span {...tooltip}>
                  <WarningInfoIcon className="text-semantic-2 h-8 w-8" />
                </span>
              )}
            </Tooltip>
          )}
          <span className="text-black">{t(ClientFormUserRoleKeys[role])}</span>
          {!disabled && (
            <UsersSelect
              users={clientUsers}
              selectedUsers={users}
              onUsersChanged={onUpdateUsers}
              assignedUserRole={role}
              assignedFormSectionId={formSectionId}
              singleSelect={role === ClientFormUserRole.Owner}
              filterAssigned={formSectionId ? (x) => x.formSectionId === formSectionId : undefined}
              formUsers={formUsers}
              assignedRequiredAction={role === ClientFormUserRole.Owner ? ownerRequiresAction : undefined}
            />
          )}
        </div>
        <div className="mr-2 transition-[padding] duration-700 [[data-accordion-open='true']_&]:pt-20">
          {users.length > 0 && <ProfileImageStack users={users} size={ImageSize.XS} />}
        </div>
      </div>
    ),
    [clientUsers, disabled, formSectionId, formUsers, onUpdateUsers, ownerRequiresAction, role, t, users, warning],
  );

  return (
    <div className="my-4 rounded-[4px] border" data-accordion-open={open}>
      <Accordion active separationBorder="content" wrapperClassName="!m-0" bodyClassName="!pb-0 !pt-0" title={accordionTitle} onChange={setOpen}>
        {users.length > 0 && (
          <div className="flex items-center pt-2 font-medium text-black">
            {enableOrdering && <div className="w-8 flex-shrink-0">{t('permissions-modal.group.order')}</div>}
            <div className="flex-grow">{t('permissions-modal.group.name')}</div>
            {requiredToggle && <div className="mr-6 flex-shrink-0">{t('permissions-modal.group.required')}</div>}
          </div>
        )}
        <div className="-mx-4 max-h-[223px] overflow-y-auto px-4">
          <Users
            users={users}
            onRemove={onUserRemove}
            role={role}
            requiredToggle={requiredToggle}
            onUserRequiredChanged={onUserRequiredChanged}
            enableOrdering={enableOrdering}
            onUsersReorder={onUsersReorder}
            searchPhrase={searchPhrase}
          />
        </div>
        {users.length === 0 && <div className="py-2">{emptySlot()}</div>}
        <div className="-mx-4 border-t px-4 empty:hidden">{footerSlot()}</div>
      </Accordion>
    </div>
  );
});

export default ClientFormRoleGroup;

const Users: FC<{
  users: ClientFormUser[];
  onRemove?: (user: ClientFormUser) => void;
  role: ClientFormUserRoleValues;
  requiredToggle?: boolean;
  onUserRequiredChanged: (user: ClientFormUser, required: boolean) => void;
  enableOrdering?: boolean;
  onUsersReorder?: (users: ClientFormUser[]) => void;
  searchPhrase: string;
}> = (props) => {
  const { users, onRemove, role, requiredToggle, onUserRequiredChanged, enableOrdering, onUsersReorder, searchPhrase } = props;
  const mounted = useRef(false);
  const { updateParentHeight } = useAccordionContext();

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      return; // don't animate on mount
    }

    setTimeout(() => {
      updateParentHeight();
    }, 50);
  }, [updateParentHeight, users.length]);

  const dndSensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const itemIds = useMemo(() => users.filter((x) => x.requiresAction).map((x) => x.id), [users]);

  const handleDragEnd = useCallback(
    (evt: DragEndEvent) => {
      const { active, over } = evt;
      if (!active || !over || active.id === over.id) return;

      const oldIndex = users.findIndex((x) => x.id === active.id);
      const newIndex = users.findIndex((x) => x.id === over.id);
      const newOrder = arrayMove(users, oldIndex, newIndex).map((x, i) => ({ ...x, sortOrder: i }));

      onUsersReorder?.(newOrder);
    },
    [onUsersReorder, users],
  );

  return (
    <DndContext
      sensors={dndSensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
    >
      <SortableContext items={itemIds} strategy={verticalListSortingStrategy}>
        {users.map((x) => (
          <UserRow
            key={role + ';' + x.id}
            user={x}
            onRemove={onRemove}
            requiredToggle={requiredToggle}
            onUserRequiredChanged={onUserRequiredChanged}
            enableOrdering={enableOrdering}
            searchPhrase={searchPhrase}
          />
        ))}
      </SortableContext>
    </DndContext>
  );
};

const UserRow: FC<{
  user: ClientFormUser;
  onRemove?: (user: ClientFormUser) => void;
  requiredToggle?: boolean;
  onUserRequiredChanged: (user: ClientFormUser, required: boolean) => void;
  enableOrdering?: boolean;
  searchPhrase: string;
}> = (props) => {
  const { user, onRemove, requiredToggle, onUserRequiredChanged, enableOrdering, searchPhrase } = props;

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: user.id, disabled: !user.requiresAction });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={`hover:bg-gray-6 group/user-row relative -mx-2 my-1 flex items-center justify-between rounded-[4px] bg-white px-2 py-1 transition-colors ${isDragging ? 'z-10' : ''}`}
    >
      <div className="flex items-center">
        {enableOrdering && (
          <div className={`flex w-8 ${isDragging ? 'cursor-grabbing' : 'cursor-grab'} items-center`} {...attributes} {...listeners}>
            {user.requiresAction && (
              <>
                <span className="inline group-hover/user-row:hidden">{typeof user.sortOrder === 'number' ? user.sortOrder + 1 : null}</span>
                <span className="hidden group-hover/user-row:inline">
                  <DragHandleIcon className="h-4 w-4" />
                </span>
              </>
            )}
          </div>
        )}
        <UserListRenderer
          id={user.id}
          value={PeopleType.Member}
          text={user.fullName ?? ''}
          userImageId={user.userImageId}
          size={ImageSize.XS}
          textHighlight={searchPhrase}
        />
      </div>
      <div className="flex items-center">
        {requiredToggle && (
          <Checkbox slider sliderSize={SliderSize.S} value={!!user.requiresAction} onChange={(v) => onUserRequiredChanged(user, v)} />
        )}
        <XIcon
          className="h-5 w-5 text-black opacity-0 transition-opacity group-hover/user-row:opacity-100"
          onClick={() => {
            onRemove?.(user);
          }}
        />
      </div>
    </div>
  );
};
